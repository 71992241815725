@tailwind base;
@tailwind components;
@tailwind utilities;

/* If the user prefers dark mode,
we of course apply color-scheme: light, as usual */
@media (prefers-color-scheme: dark) {
    html {
        color-scheme: light;
    }
}

input[type='checkbox'] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #333333;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    background: #ffffff;
    border-collapse: collapse;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

input[type='radio'] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #333333;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background: #ffffff;
    border-collapse: collapse;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

input[type='checkbox']:disabled,
input[type='radio']:disabled {
    border: 1px solid #d5cdcd;
    background: #d5cdcd;
    box-shadow: none;
    cursor: not-allowed;
}

input[type='radio']:checked {
    background: #000000;
    border: 1px solid #2f912f;
}

input[type='checkbox']:checked {
    background: #2f912f;
    border: 1px solid #2f912f;
}

input[type='checkbox']:checked:after,
input[type='radio']:checked:after {
    content: '\2714';
    color: #ffffff;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    text-shadow: 0 0 3px #444444;
    font-family: arial, Segoe UI Symbol;
}

input[type='radio']:checked:after {
    font-size: 12px;
}

/** Modal Start */

/************* Micro Modal ****************/

/**************************\
  Basic Modal Styles
\**************************/

.modal {
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
        helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
        sans-serif;
}

.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__container {
    background-color: #fff;
    padding: 30px;
    max-width: 500px;
    max-height: 100vh;
    border-radius: 4px;
    overflow-y: auto;
    box-sizing: border-box;
    position: relative !important;
}

.modal__header {
    display: flex;
    align-items: center;
}

.modal__title {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
    color: #00449e;
    box-sizing: border-box;
}

.modal__close {
    background: transparent;
    border: 0;
}

.modal__header .modal__close:before {
    content: '\2715';
}

.modal__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
}

.modal__btn {
    font-size: 0.875rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #e6e6e6;
    color: rgba(0, 0, 0, 0.8);
    border-radius: 0.25rem;
    border-style: none;
    border-width: 0;
    cursor: pointer;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    line-height: 1.15;
    margin: 0;
    will-change: transform;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.modal__btn:focus,
.modal__btn:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.modal__btn-primary {
    background-color: #00449e;
    color: #fff;
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(15%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10%);
    }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden='false'] .modal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='false'] .modal__container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='true'] .modal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='true'] .modal__container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}

/************* Micro Modal ****************/

/** Modal End */

#make-payment-form label, #saved-payment-form label {
    @apply font-semibold mt-2;
}

#make-payment-form select, #saved-payment-form select {
    @apply w-auto;
}

main h2,
.pseudo-main h2 {
    @apply text-3xl mb-2 mt-4;
}

main ul,
.pseudo-main ul {
    @apply list-disc mb-2;
}

main ul li,
.pseudo-main ul li {
    @apply ml-6;
}

main p,
.pseudo-main p {
    @apply mb-2;
}

main a,
.pseudo-main a {
    @apply underline text-blue-600;
}

main {
    @apply px-2;
}

.logo-bg {
    background: #000000;
}

.bg-logo-blue {
    background: #4a60aa;
}

.shadow-logo {
    box-shadow: 0.5px 0.5px 2px #fff;
}

#apply-now-form h1 {
    @apply text-3xl font-semibold;
}

.ReactModal__Content {
    max-height: 94%;
}

#progress-bar li {
    margin-left: 0;
}

.stack {
    @apply p-1;
}

.stack input[type='radio'] {
    margin: 0 auto;
    text-align: center;
    display: block;
}

.stack label {
    width: 100%;
    text-align: center;
}

.stack .field {
    padding: 0 !important;
}

#pay-frequency-form .submit-button {
    display: none !important;
}

.tooltip-button {
    @apply bg-portmain-300 text-white;
    font-size: 0.8em;
    border-radius: 50%;
    padding: 0px 5px;
    font-size: 1.2em;
    font-weight: bold;
    margin-left: 2px;
}

.tip {
    padding: 5px;
    position: absolute;
    z-index: 50;
    cursor: default;
    animation: 0.5s fadeIn;
}

.tip-content {
    padding: 10px;
    border-radius: 5px;
    background: #000;
    color: #fff;
    font-size: 1em;
    font-weight: normal;
}

.tip-hider {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 51;
}

.tip:after {
    content: '';
    width: 0;
    height: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    border: 5px solid transparent;
    border-bottom: none;
    border-top-color: #000;
}

.invisible-2 {
    visibility: none;
    opacity: 0;
    z-index: -1;
    padding: 5px;
    position: absolute;
    cursor: default;
    animation: 0.5s fadeIn;
}

#pay-frequency-form button > * {
    pointer-events: none;
}

.side-by-side-approve {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

a.a {
    @apply underline text-blue-500 hover:text-portmain-400;
}

a * {
    pointer-events: none;
}

#update-info-form .button-controls {
    @apply justify-center;
}

#update-info-form .submit-button {
    @apply text-white bg-portmain-300 hover:bg-portmain-300 rounded px-4 py-2;
}

.ReactModal__Overlay--after-open {
    z-index: 51;
}

.shake {
    animation: shake-animation 4.72s ease infinite;
}

@keyframes shake-animation {
    0% {
        transform: translate(0, 0);
    }
    1.78571% {
        transform: translate(5px, 0);
    }
    3.57143% {
        transform: translate(0, 0);
    }
    5.35714% {
        transform: translate(5px, 0);
    }
    7.14286% {
        transform: translate(0, 0);
    }
    8.92857% {
        transform: translate(5px, 0);
    }
    10.71429% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
