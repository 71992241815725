#hero {
    background-image: url('/public/src/img/newhero_3.png');
}

#title {
    text-shadow: 2px 4px 5px rgba(0, 0, 0, 0.5);
}

#leading {
    text-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
}

#disclosure {
    text-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
}